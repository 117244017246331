var render = function () {
  var _vm$insight, _vm$insight2, _vm$insight3, _vm$insight3$author, _vm$insight4, _vm$insight4$author, _vm$insight5, _vm$insight5$author, _vm$insight6, _vm$insight6$author, _vm$insight7, _vm$insight7$author, _vm$insight8, _vm$insight9, _vm$insight10, _vm$insight11, _vm$insight12, _vm$insight12$author, _vm$insight13, _vm$insight13$author, _vm$insight14, _vm$insight14$author, _vm$insight15, _vm$insight15$author, _vm$insight16, _vm$insight16$author, _vm$insight17, _vm$insight17$author, _vm$insight18, _vm$insight18$author, _vm$insight19, _vm$insight19$author, _vm$insight20, _vm$insight20$author, _vm$insight21, _vm$insight21$author, _vm$insight22, _vm$insight22$author, _vm$insight23, _vm$insight23$author, _vm$insight24, _vm$insight24$author, _vm$insight25, _vm$insight25$author, _vm$insight26, _vm$insight26$author;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "insight",
    staticClass: "insight"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('GenproxBreadcrumbs', {
    attrs: {
      "title": ((_vm$insight = _vm.insight) === null || _vm$insight === void 0 ? void 0 : _vm$insight.title) || ''
    }
  }), _c('div', {
    staticClass: "insight__container"
  }, [_c('h1', {
    staticClass: "insight__title"
  }, [_vm._v(_vm._s(((_vm$insight2 = _vm.insight) === null || _vm$insight2 === void 0 ? void 0 : _vm$insight2.title) || ''))]), _c('div', {
    staticClass: "post-details"
  }, [_c('div', {
    staticClass: "post-details__img"
  }, [_c('user-presentation', {
    attrs: {
      "image": (_vm$insight3 = _vm.insight) === null || _vm$insight3 === void 0 ? void 0 : (_vm$insight3$author = _vm$insight3.author) === null || _vm$insight3$author === void 0 ? void 0 : _vm$insight3$author.photo,
      "name": (_vm$insight4 = _vm.insight) === null || _vm$insight4 === void 0 ? void 0 : (_vm$insight4$author = _vm$insight4.author) === null || _vm$insight4$author === void 0 ? void 0 : _vm$insight4$author.firstName
    }
  })], 1), _c('div', {
    staticClass: "post-details__inner"
  }, [_c('p', {
    staticClass: "post-details__name"
  }, [_vm._v(_vm._s((_vm$insight5 = _vm.insight) === null || _vm$insight5 === void 0 ? void 0 : (_vm$insight5$author = _vm$insight5.author) === null || _vm$insight5$author === void 0 ? void 0 : _vm$insight5$author.firstName) + " " + _vm._s((_vm$insight6 = _vm.insight) === null || _vm$insight6 === void 0 ? void 0 : (_vm$insight6$author = _vm$insight6.author) === null || _vm$insight6$author === void 0 ? void 0 : _vm$insight6$author.surname))]), _c('p', {
    staticClass: "post-details__desc"
  }, [_vm._v(_vm._s(((_vm$insight7 = _vm.insight) === null || _vm$insight7 === void 0 ? void 0 : (_vm$insight7$author = _vm$insight7.author) === null || _vm$insight7$author === void 0 ? void 0 : _vm$insight7$author.professionalHeadline) || 'Author'))])]), _c('p', {
    staticClass: "post-details__read-time"
  }, [_vm._v(_vm._s(_vm.readingTime) + " min read")])]), (_vm$insight8 = _vm.insight) !== null && _vm$insight8 !== void 0 && _vm$insight8.photoHeader ? _c('div', {
    staticClass: "insight__thumbnail"
  }, [_c('img', {
    attrs: {
      "src": (_vm$insight9 = _vm.insight) === null || _vm$insight9 === void 0 ? void 0 : _vm$insight9.photoHeader,
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "insight__excerpt"
  }, [_vm._v(_vm._s((_vm$insight10 = _vm.insight) === null || _vm$insight10 === void 0 ? void 0 : _vm$insight10.excerpt))]), _c('div', {
    staticClass: "insight__content",
    domProps: {
      "innerHTML": _vm._s(((_vm$insight11 = _vm.insight) === null || _vm$insight11 === void 0 ? void 0 : _vm$insight11.content) || '')
    }
  }), (_vm$insight12 = _vm.insight) !== null && _vm$insight12 !== void 0 && (_vm$insight12$author = _vm$insight12.author) !== null && _vm$insight12$author !== void 0 && _vm$insight12$author.professionalDescription ? _c('div', {
    class: ['post-details', (_vm$insight13 = _vm.insight) !== null && _vm$insight13 !== void 0 && (_vm$insight13$author = _vm$insight13.author) !== null && _vm$insight13$author !== void 0 && _vm$insight13$author.professionalDescription ? 'post-details--big' : '']
  }, [_c('div', {
    staticClass: "post-details__img"
  }, [_c('user-presentation', {
    attrs: {
      "image": (_vm$insight14 = _vm.insight) === null || _vm$insight14 === void 0 ? void 0 : (_vm$insight14$author = _vm$insight14.author) === null || _vm$insight14$author === void 0 ? void 0 : _vm$insight14$author.photo,
      "name": (_vm$insight15 = _vm.insight) === null || _vm$insight15 === void 0 ? void 0 : (_vm$insight15$author = _vm$insight15.author) === null || _vm$insight15$author === void 0 ? void 0 : _vm$insight15$author.firstName
    }
  })], 1), _c('div', {
    staticClass: "post-details__inner"
  }, [_c('p', {
    staticClass: "post-details__name"
  }, [_vm._v(_vm._s((_vm$insight16 = _vm.insight) === null || _vm$insight16 === void 0 ? void 0 : (_vm$insight16$author = _vm$insight16.author) === null || _vm$insight16$author === void 0 ? void 0 : _vm$insight16$author.firstName) + " " + _vm._s((_vm$insight17 = _vm.insight) === null || _vm$insight17 === void 0 ? void 0 : (_vm$insight17$author = _vm$insight17.author) === null || _vm$insight17$author === void 0 ? void 0 : _vm$insight17$author.surname))]), _c('p', {
    staticClass: "post-details__desc"
  }, [_vm._v(_vm._s((_vm$insight18 = _vm.insight) === null || _vm$insight18 === void 0 ? void 0 : (_vm$insight18$author = _vm$insight18.author) === null || _vm$insight18$author === void 0 ? void 0 : _vm$insight18$author.professionalHeadline))]), (_vm$insight19 = _vm.insight) !== null && _vm$insight19 !== void 0 && (_vm$insight19$author = _vm$insight19.author) !== null && _vm$insight19$author !== void 0 && _vm$insight19$author.professionalDescription ? _c('p', {
    staticClass: "post-details__body",
    domProps: {
      "innerHTML": _vm._s((_vm$insight20 = _vm.insight) === null || _vm$insight20 === void 0 ? void 0 : (_vm$insight20$author = _vm$insight20.author) === null || _vm$insight20$author === void 0 ? void 0 : _vm$insight20$author.professionalDescription)
    }
  }) : _vm._e(), (_vm$insight21 = _vm.insight) !== null && _vm$insight21 !== void 0 && (_vm$insight21$author = _vm$insight21.author) !== null && _vm$insight21$author !== void 0 && _vm$insight21$author.facebook || (_vm$insight22 = _vm.insight) !== null && _vm$insight22 !== void 0 && (_vm$insight22$author = _vm$insight22.author) !== null && _vm$insight22$author !== void 0 && _vm$insight22$author.linkedIn ? _c('div', {
    staticClass: "post-details__socials"
  }, [(_vm$insight23 = _vm.insight) !== null && _vm$insight23 !== void 0 && (_vm$insight23$author = _vm$insight23.author) !== null && _vm$insight23$author !== void 0 && _vm$insight23$author.facebook ? _c('a', {
    attrs: {
      "target": "_blank",
      "href": (_vm$insight24 = _vm.insight) === null || _vm$insight24 === void 0 ? void 0 : (_vm$insight24$author = _vm$insight24.author) === null || _vm$insight24$author === void 0 ? void 0 : _vm$insight24$author.facebook
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/facebook.svg"),
      "alt": "Facebook"
    }
  })]) : _vm._e(), (_vm$insight25 = _vm.insight) !== null && _vm$insight25 !== void 0 && (_vm$insight25$author = _vm$insight25.author) !== null && _vm$insight25$author !== void 0 && _vm$insight25$author.linkedIn ? _c('a', {
    attrs: {
      "target": "_blank",
      "href": (_vm$insight26 = _vm.insight) === null || _vm$insight26 === void 0 ? void 0 : (_vm$insight26$author = _vm$insight26.author) === null || _vm$insight26$author === void 0 ? void 0 : _vm$insight26$author.linkedIn
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/linkedin.svg"),
      "alt": "Linkedin"
    }
  })]) : _vm._e()]) : _vm._e()])]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }