











































import Vue from 'vue'
import Component from 'vue-class-component'
import InsightFilters from '../components/InsightFilters.vue'
import PostBox from '../components/PostBox.vue';
import SygniBreadcrumbs from '@/components/layout/SygniBreadcrumbs.vue';
import NavigationPost from '../components/NavigationPost.vue';
import AuthorPanel from '../components/AuthorPanel.vue';
import SygniTags from '@/components/layout/SygniTags.vue';
import { BPagination } from 'bootstrap-vue';
import { InsightData } from '../store/types';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import GenproxBreadcrumbs from '@/components/layout/GenproxBreadcrumbs.vue';
import UserPresentation from '@/components/UserPresentation.vue';

@Component({
  components: { InsightFilters, PostBox, BPagination, SygniBreadcrumbs, SygniTags, NavigationPost, AuthorPanel, SygniLoader, GenproxBreadcrumbs, UserPresentation },
})
export default class InsightModule extends Vue {
  isLoading: boolean = false;
  insight: InsightData = null;

  get readingTime() {
    const words = this.insight?.content?.trim().split(/\s+/).length;
    const wpm = 265

    return Math.ceil(words / wpm);
  }

  get formattedContent() {
    const formattedContent: HTMLElement = document.createElement('div')

    formattedContent.innerHTML = this.insight?.content || ''

    const images = (formattedContent as HTMLElement).querySelectorAll('img');

    images?.forEach((image: HTMLImageElement) => {
      const wrapper: HTMLDivElement = document.createElement('div')
      wrapper.classList.add('fancybox')
      this.wrapImage(image, wrapper)
    })

    return formattedContent?.innerHTML || ''
  }

  wrapImage(el: HTMLImageElement, wrapper: HTMLDivElement) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
  }

  async getInsight() {
    const insight = await this.$store.dispatch('insights/getInsight', this.$route.params.slug);
    this.insight = insight;
    this.isLoading = false;
  }
  
  async beforeMount() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('profile/getDictionaries');
      await this.getInsight();
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
      this.$router.push({ name: 'insights' })
    }
  }
}
